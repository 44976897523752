import Helper from '../../config/Helper';
import Config from '../../config/Config';
import { elid } from '../../config/Element';

const urlParam = location.href.split('/');
const querySearch = Config.mode === 'dev' ? urlParam[6] : urlParam[5];
const urlPage = `${Config.url}/pasien/detail-pasien/${querySearch}`;

function handleRenderThead(title, eatingTime) {
    const html = `
        <div class="card card-primary mt-3">
            <div class="card-header">
                <h5>Table Menu ${title}</h5>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table id="table-${eatingTime}" class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th rowspan="2" class="align-middle text-center">Nama Menu</th>
                                <th rowspan="2" class="align-middle text-center">Bahan</th>
                                <th colspan="5" style="text-align: center;">Kandungan Zat Gizi</th>
                            </tr>
                            <tr>
                                <th>Berat(gram)</th>
                                <th>Energi(gram)</th>
                                <th>Protein(gram)</th>
                                <th>Lemak(gram)</th>
                                <th>Karbohidrat(gram)</th>
                            </tr>
                        </thead>
                        <tbody id="tbody-${eatingTime}"></tbody>
                        <tfoot id="tfoot-${eatingTime}"></tfoot>
                    </table>
                </div>
            </div>
        </div>
    `;
    return html;
}

function handleTfooter(eatingTime, data) {
    const html = `
        <tr>
            <th colspan="3">Sub Total</th>
            <td>${data.energi}</td>
            <td>${data.protein}</td>
            <td>${data.lemak}</td>
            <td>${data.kh}</td>
        </tr>
    `;
    elid(`tfoot-${eatingTime}`).innerHTML = html;
}

function renderLoadTableBahan(bahans) {
    let render = '';
    bahans.forEach((item, key) => {
        if (key !== 0) {
            const html = `
            <tr>
                <td>${item.nama_bahan}</td>
                <td>${item.berat}</td>
                <td>${item.energi}</td>
                <td>${item.protein}</td>
                <td>${item.lemak}</td>
                <td>${item.kh}</td>
            </tr>
        `;
            render += html;
        }
    });
    return render;
}

function handleLoadTable(eatingTime, data) {
    let render = '';
    if (data.length) {
        data.forEach((item) => {
            console.log(item);
            const html = `
            <tr>
                <td rowspan="${item.bahans.length}">${item.nama_menu}</td>
                <td>${item.bahans[0].nama_bahan}</td>
                <td>${item.bahans[0].berat}</td>
                <td>${item.bahans[0].energi}</td>
                <td>${item.bahans[0].protein}</td>
                <td>${item.bahans[0].lemak}</td>
                <td>${item.bahans[0].kh}</td>

            </tr>
                ${renderLoadTableBahan(item.bahans)}
        `;
            render += html;
        });
    } else {
        render = `
            <tr>
                <td colspan="7" style="text-align:center;">Tidak ada data untuk ditampilkan</td>
            </tr>
        `;
    }
    elid(`tbody-${eatingTime}`).innerHTML = render;
}

function renderDataPasien(data) {
    const html = `
                <div class="row">
                    <div class="col-md-6">
                        <table class="table table-striped">
                            <tr>
                                <td style="text-align:center;" colspan="3"><b>DATA PASIEN</b></td>
                            </tr>
                            <tr>
                                <td style="width: 20px;">Nama</td>
                                <td>:</td>
                                <td>${data.nama_pasien}</td>
                            </tr>
                            <tr>
                                <td style="width: 20px;">Usia</td>
                                <td>:</td>
                                <td>${data.usia}</td>
                            </tr>
                            <tr>
                                <td>Jenis Kelamin</td>
                                <td>:</td>
                                <td>${data.jenis_kelamin}</td>
                            </tr>
                            <tr>
                                <td>Diagnosa Medis</td>
                                <td>:</td>
                                <td>${data.diagnosa_medis}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-md-6">
                        <table class="table table-striped">
                            <tr>
                                <td style="text-align:center;" colspan="3"><b>PERANCANGAN MENU</b></td>
                            </tr>
                            <tr>
                                <td style="width: 20px;">Energi</td>
                                <td>:</td>
                                <td>${data.energi}</td>
                            </tr>
                            <tr>
                                <td>Protein</td>
                                <td>:</td>
                                <td>${data.protein}</td>
                            </tr>
                            <tr>
                                <td>Lemak</td>
                                <td>:</td>
                                <td>${data.lemak}</td>
                            </tr>
                            <tr>
                                <td>Karbohidrat</td>
                                <td>:</td>
                                <td>${data.karbohidrat}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-md-12">
                        ${handleRenderThead('Pagi', 'pagi')}
                    </div>
                    <div class="col-md-12">
                        ${handleRenderThead('Snack Siang', 'snack_siang')}
                    </div>
                    <div class="col-md-12">
                        ${handleRenderThead('Siang', 'siang')}
                    </div>
                    <div class="col-md-12">
                        ${handleRenderThead('Snack Sore', 'snack_sore')}
                    </div>
                    <div class="col-md-12">
                        ${handleRenderThead('Malam', 'malam')}
                    </div>
                    <div class="col-md-12">
                        ${handleRenderThead('Snack Malam', 'snack_malam')}
                    </div>
                    <div class="col-md-12" id="pemenuhan_gizi"></div>

                </div>

    `;
    elid('content').innerHTML = html;
}
function handleTableTotal(data) {
    let html = `
        <table class="table table-bordered">
          <thead>
            <tr>
              <th rowspan="2">Total</th>
              <th colspan="5">Kandungan gizi</th>
            </tr>
            <tr>
              <th>Energi(gram)</th>
              <th>Protein(gram)</th>
              <th>Lemak(gram)</th>
              <th>Karbohidrat(gram)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total Keseluruhan</td>
              <td>${data.pasien.energi}</td>
              <td>${data.pasien.protein}</td>
              <td>${data.pasien.lemak}</td>
              <td>${data.pasien.kh}</td>
            </tr>
            <tr>
              <td>total kebutuhan</td>
              <td>${data.total_keseluruhan.energi}</td>
              <td>${data.total_keseluruhan.protein}</td>
              <td>${data.total_keseluruhan.lemak}</td>
              <td>${data.total_keseluruhan.kh}</td>
            </tr>
            <tr>
              <td>% Pemenuhan Kebutuhan</td>
              <td>${data.pemenuhan_gizi.energi} %</td>
              <td>${data.pemenuhan_gizi.protein} %</td>
              <td>${data.pemenuhan_gizi.lemak} %</td>
              <td>${data.pemenuhan_gizi.kh} %</td>
            </tr>
          </tbody>
        </table>
    `;
    elid('pemenuhan_gizi').innerHTML = html;
}

function sumSubTotal(data) {
    const arrEnergi = [];
    const arrProtein = [];
    const arrLemak = [];
    const arrKh = [];
    let result = {};
    if (data.length !== 0) {
        data.forEach((item) => {
            item.bahans.forEach((bahan) => {
                arrEnergi.push(bahan.energi);
                arrProtein.push(bahan.protein);
                arrLemak.push(bahan.lemak);
                arrKh.push(bahan.kh);
            });
        });
        result = {
            energi: arrEnergi
                .map((val) => parseFloat(val))
                .reduce((a, c) => a + c)
                .toFixed(2),
            protein: arrProtein
                .map((val) => parseFloat(val))
                .reduce((a, c) => a + c)
                .toFixed(2),
            lemak: arrLemak
                .map((val) => parseFloat(val))
                .reduce((a, c) => a + c)
                .toFixed(2),
            kh: arrKh
                .map((val) => parseFloat(val))
                .reduce((a, c) => a + c)
                .toFixed(2),
        };
    } else {
        result = {
            energi: 0,
            protein: 0,
            lemak: 0,
            kh: 0,
        };
    }
    return result;
}

function sumTotalKeseluruhan(data, gizi) {
    return Object.entries(data)
        .map(([_, value]) => {
            switch (gizi) {
                case 'energi':
                    return parseFloat(value.energi);
                case 'protein':
                    return parseFloat(value.protein);
                case 'lemak':
                    return parseFloat(value.lemak);
                case 'kh':
                    return parseFloat(value.kh);
            }
        })
        .reduce((a, c) => a + c)
        .toFixed(2);
}
function loadData() {
    $.ajax({
        url: urlPage,
        success: function (result) {
            // console.log(result.menu);
            const timeId =
                Config.mode === 'dev'
                    ? [1, 2, 3, 4, 5, 6]
                    : ['1', '2', '3', '4', '5', '6'];
            const menuPagi = result.menu.filter(
                (item) => item.jam_id === timeId[0],
            );
            const menuSnackSiang = result.menu.filter(
                (item) => item.jam_id === timeId[1],
            );
            const menuSiang = result.menu.filter(
                (item) => item.jam_id === timeId[2],
            );
            const menuSore = result.menu.filter(
                (item) => item.jam_id === timeId[3],
            );
            const menuMalam = result.menu.filter(
                (item) => item.jam_id === timeId[4],
            );
            const menuSnackMalam = result.menu.filter(
                (item) => item.jam_id === timeId[5],
            );
            // console.log(menuPagi);
            const sumMenuPagi = sumSubTotal(menuPagi);
            const sumMenuSnackSiang = sumSubTotal(menuSnackSiang);
            const sumMenuSiang = sumSubTotal(menuSiang);
            const sumMenuSore = sumSubTotal(menuSore);
            const sumMenuMalam = sumSubTotal(menuMalam);
            const sumMenuSnackMalam = sumSubTotal(menuSnackMalam);
            const data = {
                pagi: menuPagi,
                snack_siang: menuSnackSiang,
                siang: menuSiang,
                snack_sore: menuSore,
                malam: menuMalam,
                snack_malam: menuSnackMalam,
            };
            const dataSum = {
                pagi: sumMenuPagi,
                snack_siang: sumMenuSnackSiang,
                siang: sumMenuSiang,
                snack_sore: sumMenuSore,
                malam: sumMenuMalam,
                snack_malam: sumMenuSnackMalam,
            };

            let dataTotal = {
                pasien: {
                    energi: result.energi,
                    protein: result.protein,
                    lemak: result.lemak,
                    kh: result.karbohidrat,
                },
                total_keseluruhan: {
                    energi: sumTotalKeseluruhan(dataSum, 'energi'),
                    protein: sumTotalKeseluruhan(dataSum, 'protein'),
                    lemak: sumTotalKeseluruhan(dataSum, 'lemak'),
                    kh: sumTotalKeseluruhan(dataSum, 'kh'),
                },
            };
            let pemenuhanGizi = Object.assign(dataTotal, {
                pemenuhan_gizi: {
                    energi: parseFloat(
                        (dataTotal.total_keseluruhan.energi /
                            dataTotal.pasien.energi) *
                            100,
                    ).toFixed(2),
                    protein: parseFloat(
                        (dataTotal.total_keseluruhan.protein /
                            dataTotal.pasien.protein) *
                            100,
                    ).toFixed(2),
                    lemak: parseFloat(
                        (dataTotal.total_keseluruhan.lemak /
                            dataTotal.pasien.lemak) *
                            100,
                    ).toFixed(2),
                    kh: parseFloat(
                        (dataTotal.total_keseluruhan.kh / dataTotal.pasien.kh) *
                            100,
                    ).toFixed(2),
                },
            });
            // console.log(data);
            // console.log(result);
            renderDataPasien(result);
            Object.entries(data).map(([key, value]) =>
                handleLoadTable(key, value),
            );
            Object.entries(dataSum).map(([key, value]) =>
                handleTfooter(key, value),
            );
            handleTableTotal(pemenuhanGizi);
            // Object.entries(dataSum).map(([key, value]) =>
            //     console.log(value.energi),
            // );
        },
    });
}

function init() {
    loadData();
    elid('print').onclick = () =>
        window.open(
            `${Config.url}/pasien/print-data-pasien/${+querySearch}`,
            '_blank',
        );
    elid('btn-back').onclick = () =>
        location.assign(
            `${Config.url}/pasien/create-menu-pasien/${+querySearch}`,
        );
}

export { init, urlPage };
