import axios from 'axios';
import Config from '../config/Config';
import Helper from '../config/Helper';

const urlPage = `${Config.url}/pengaturan/profile`;

function renderProfile(data) {
    const render = document.querySelector('.profile-widget');
    let foto = '';
    if (data.avatar !== null) {
        foto = `${Config.url}/public/storage/${data.avatar}`;
    } else {
        foto = `${Config.url}/assets/img/avatar/avatar-1.png`;
    }
    const html = `
            <div class="profile-widget-header">
                <img alt="image" src="${foto}" class="rounded-circle profile-widget-picture">
                <div class="profile-widget-items">
                    <div class="profile-widget-item">
                        <div class="profile-widget-item-label">Username</div>
                        <div class="profile-widget-item-value">${data.username}</div>
                    </div>
                    <div class="profile-widget-item">
                        <div class="profile-widget-item-label">Email</div>
                        <div class="profile-widget-item-value">${data.email}</div>
                    </div>
                </div>
            </div>
            <div class="profile-widget-description">
                <div class="profile-widget-name">${data.name}<div class="text-muted d-inline font-weight-normal"><div class="slash"></div>${data.roles[0].name}</div></div>
                <p>${data.notelp}</p>
               ${data.alamat}
            </div>
            <div class="card-footer">
                <button type="button" class="btn btn-primary btn-block update">Edit Profile</button>
            </div>
    `;
    render.innerHTML = html;
}

const showProfile = async () => {
    const dataProfile = await axios(`${urlPage}/detail`);
    renderProfile(dataProfile.data);
};

function handleEdit(e) {
    if (e.target.classList.contains('update')) {
        axios(`${urlPage}/detail`).then((res) => {
            const notAllowed = [
                'created_at',
                'updated_at',
                'roles',
                'avatar',
                'password',
                'is_active',
                'email_verified_at',
            ];
            for (const i in res.data) {
                console.log(i);
                if (!notAllowed.includes(i)) {
                    document.getElementById(i).value = res.data[i];
                }
            }
            const footer = document.getElementById('save');
            const button = `<button class="btn btn-primary">Save Changes</button>`;
            footer.innerHTML = button;
        });
    }
}

const form = document.getElementById('form-profile');
async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(form);
    let icon = '';
    const footer = document.getElementById('save');
    try {
        const store = await axios({
            method: 'POST',
            url: `${urlPage}/simpan`,
            data: formData,
        });
        if (store.data.error === undefined) {
            for (const i in store.data.success) {
                Helper.hapusValidasi(i);
            }
            icon = 'success';
            form.reset();
            footer.innerHTML = '';
            showProfile();
        } else {
            const error = Object.entries(store.data.error);
            error.map(([key, value]) => Helper.addValidation(key, value));
            icon = 'error';
        }
        Helper.notifMsg(icon, store.data.message);
    } catch (err) {
        console.error(err);
        Helper.notifMsg('error', 'Terjadi Kesalahan Server');
    } finally {
        Helper.showLoading();
    }
}

const init = () => {
    showProfile();
    form.addEventListener('submit', handleSubmit);
    Helper.clickEvent(handleEdit);
};

export { init, urlPage };
