import Config from '../../config/Config';
import Helper from '../../config/Helper';
import { elid, elqs } from '../../config/Element';

const urlPage = `${Config.url}/pasien`;

function handleLoadTable() {
    $('#table').DataTable({
        processing: true,
        serverSide: true,
        responsive: true,
        lengtChange: true,
        autoWidth: false,
        ajax: {
            url: `${urlPage}/json-dt`,
        },
        columns: [
            { data: 'DT_RowIndex', name: 'id' },
            { data: 'nama_pasien', name: 'nama_pasien' },
            { data: 'jenis_kelamin', name: 'jenis_kelamin' },
            { data: 'diagnosa_medis', name: 'diagnosa_medis' },
            { data: 'action', name: 'action' },
        ],
        order: [[0, 'desc']],
        destroy: true,
    });
}

function init() {
    handleLoadTable();
}

export { init, urlPage };
