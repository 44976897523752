import axios from 'axios';
import Config from '../config/Config';
import { elid } from '../config/Element';
import Helper from '../config/Helper';

const urlPage = `${Config.url}/pengaturan/user`;
const form = elid('form-user');
const parentPassword = elid('pass');
const parentPassConf = elid('passconf');
const password = elid('password');

function handleLoadData() {
    $('#table').DataTable({
        processing: true,
        serverSide: true,
        responsive: true,
        lengtChange: true,
        autoWidth: false,
        ajax: {
            url: `${urlPage}/json-dt`,
        },
        columns: [
            { data: 'DT_RowIndex', name: 'id' },
            { data: 'name', name: 'name' },
            { data: 'username', name: 'username' },
            { data: 'notelp', name: 'notelp' },
            { data: 'role', name: 'role' },
            {
                data: 'is_active',
                render: (data) => {
                    switch (data) {
                        case '0':
                            return `<p class="badge badge-danger">Non-Aktif</p>`;
                        case '1':
                            return `<p class="badge badge-success">Aktif</p>`;
                        default:
                            return `<p class="badge badge-info">Menunggu</p>`;
                    }
                },
                name: 'is_active',
            },
            { data: 'action', name: 'action' },
        ],
        order: [[0, 'desc']],
        destroy: true,
    });
}

function showModal(e) {
    if (e.target.id === 'modalTambah') {
        const id = elid('id');
        if (id.value !== '') {
            id.value = '';
        }
        parentPassword.innerHTML = '';
        parentPassConf.innerHTML = '';
        form.reset();
        $('#exampleModal').modal({
            backdrop: 'static',
        });
    }
}

const createInputPassword = () => {
    const password = document.createElement('input');
    const passConfirmation = document.createElement('input');
    const label = document.createElement('label');
    const labelConf = document.createElement('label');
    label.append('Password');
    labelConf.append('Password Confirmation');
    password.setAttribute('class', 'form-control');
    password.setAttribute('id', 'password');
    password.setAttribute('name', 'password');
    password.setAttribute('type', 'password');
    passConfirmation.setAttribute('class', 'form-control');
    passConfirmation.setAttribute('id', 'password_confirmation');
    passConfirmation.setAttribute('name', 'password_confirmation');
    passConfirmation.setAttribute('type', 'password');
    parentPassword.append(label);
    parentPassword.append(password);
    parentPassConf.append(labelConf);
    parentPassConf.append(passConfirmation);
};

const checkPasswordConfirmation = () => {
    const password = elid('password');
    const passconf = elid('passconf');
    if (password.value !== passconf.value) {
        const div = document.createElement('div');
        div.setAttribute('class', 'password');
        password.classList.add('is-invalid');
        parentPassword.append(div);
    }
};

function loadDataById(id) {
    return new Promise((resolve, reject) => {
        try {
            resolve(axios(`${urlPage}/show/${id}`));
        } catch (error) {
            reject(new Error('Terjadi Kesalahan Server'));
        }
    });
}

function renderDetailData(data) {
    const bodyModal = elid('modal-detail');
    let render = '';
    let html = `
    <div class="row">
        <div class="col-md-12">
            <table class="table table-striped">
            <tr>
                <td>Nama</td>
                <td>:</td>
                <td>${data.name}</td>
            </tr>
            <tr>
                <td>Username</td>
                <td>:</td>
                <td>${data.username}</td>
            </tr>
            <tr>
                <td>Jenis Kelamin</td>
                <td>:</td>
                <td>${data.jenis_kelamin}</td>
            </tr>
            <tr>
                <td>Email</td>
                <td>:</td>
                <td>${data.email}</td>
            </tr>
            <tr>
                <td>No Telepon</td>
                <td>:</td>
                <td>${data.notelp !== null ? data.notelp : '-'}</td>
            </tr>
            <tr>
                <td>Alamat</td>
                <td>:</td>
                <td>${data.alamat !== null ? data.alamat : '-'}</td>
            </tr>
            </table>
        </div>
    </div>
    `;
    render += html;
    return (bodyModal.innerHTML = render);
}

async function handleDetail(e) {
    if (e.target.classList.contains('detail')) {
        $('#modalDetail').modal({
            show: true,
            backdrop: 'static',
        });
        try {
            const id = e.target.dataset.id || e.target.parentNode.dataset.id;
            console.log(e.target.parentNode)
            const data = await loadDataById(id);
            renderDetailData(data.data);
        } catch (err) {
            console.error(err);
        }
    }
}

async function handleEdit(e) {
    if (e.target.classList.contains('update')) {
        const id = e.target.dataset.id || e.target.parentNode.dataset.id;
        $('#exampleModal').modal({
            show: true,
            backdrop: 'static',
        });
        if (password !== undefined) {
            parentPassword.innerHTML = '';
            parentPassConf.innerHTML = '';
            createInputPassword();
        }
        try {
            const getData = await loadDataById(id);
            const notAllowed = [
                'created_at',
                'updated_at',
                'email_verified_at',
                'avatar',
                'roles',
            ];
            for (const key in getData.data) {
                if (!notAllowed.includes(key)) {
                    elid(key).value = getData.data[key];
                }
            }
            if (getData.data.roles[0] !== null) {
                elid('role_id').value = getData.data.roles[0].id;
            }
        } catch (error) {
            console.error(error);
        }
    }
}

function handleDelete(e) {
    if (e.target.classList.contains('delete')) {
        const id = e.target.dataset.id || e.target.parentNode.dataset.id;
        const urlDelete = `${urlPage}/hapus/${+id}`;
        swal({
            title: 'Apakah Kamu Yakin ?',
            text: 'Setelah dihapus anda tidak dapat memulihkan data ini!',
            icon: 'warning',
            buttons: ['No, cancel!', 'Yes, delete it!'],
        }).then((result) => {
            if (result) {
                axios
                    .delete(urlDelete)
                    .then((res) => {
                        handleLoadData();
                        swal({
                            title: 'Pesan!',
                            text: res.data.message,
                            icon: 'success',
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                        Helper.notifMsg(
                            'warning',
                            'Data ini tidak boleh dihapus',
                        );
                    });
            }
        });
    }
}

async function handleSubmit(e) {
    e.preventDefault();
    let icon = '';
    const formData = new FormData(form);
    Helper.showLoading();
    try {
        const store = await axios.post(`${urlPage}/simpan`, formData);
        if (store.data.errors === undefined) {
            for (const key in store.data.success) {
                Helper.hapusValidasi(key);
            }
            icon = 'success';
            $('#exampleModal').modal('hide');
            handleLoadData();
            form.reset();
        } else {
            const errors = Object.entries(store.data.errors);
            if (password !== undefined) {
                checkPasswordConfirmation();
            }
            console.log(errors);
            errors.map(([key, value]) => {
                Helper.addValidation(key, value);
            });
            icon = 'error';
        }
        Helper.notifMsg(icon, store.data.message);
    } catch (error) {
        console.error(error);
    }
}

function init() {
    handleLoadData();
    form.addEventListener('submit', handleSubmit);
    Helper.clickEvent([showModal, handleEdit, handleDelete, handleDetail]);
}

export { init, urlPage };
