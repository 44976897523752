import Config from '../../config/Config';
import Helper from '../../config/Helper';
import { elid } from '../../config/Element';
import axios from 'axios';

const urlPage = `${Config.url}/register`;
const form = elid('form-register');
const alertLoading = elid('alert-loading');

async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(form);
    let icon = '';
    try {
        const store = await axios({
            method: 'POST',
            url: `${urlPage}/simpan`,
            data: formData,
        });
        if (store.data.errors === undefined) {
            for (const key in store.data.success) {
                Helper.hapusValidasi(key);
            }
            swal({
                title: 'Pesan!',
                text: store.data.message,
                icon: 'success',
                buttons: ['Cancel', 'Lanjut Login'],
            }).then((result) => {
                if (result) {
                    location.assign(`${Config.url}/login`);
                }
            });
        } else {
            const errors = Object.entries(store.data.errors);
            errors.map(([key, value]) => Helper.addValidation(key, value));
            icon = 'error';
            Helper.notifMsg(icon, store.data.message);
        }
    } catch (error) {
        console.error(error);
        Helper.notifMsg('error', 'Terjadi Kesalahan Server');
    } finally {
        let i = 0;
        alertLoading.style.display = '';
        alertLoading.innerText = 'Mohon Tunggu Sebentar...';
        const start = new Date().getTime();
        const counter = setInterval(function () {
            i++;
            if (i === 100) {
                clearInterval(counter);
                alertLoading.style.display = 'none';
                alertLoading.innerText = '';
            }
        }, start - new Date().getTime());
    }
}

function init() {
    // console.log(urlPage);
    form.addEventListener('submit', handleSubmit);
}

export { init, urlPage };
