import Config from '../../config/Config'

const urlPage = `${Config.url}/kontak-kami`

function  init() {
    let option = {
        center : [-7.322927,112.738856],
        zoom : 13
      }
      const mymap = L.map('mapid',option);
      L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
          attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 18,
          id: 'mapbox/streets-v11',
          tileSize: 512,
          zoomOffset: -1,
          accessToken: 'sk.eyJ1IjoiZGRyYWcyMyIsImEiOiJja243YzU4dGQwbWZzMnVueDQ4enBvbTB0In0.-GtSuM-HGoCqFkZVE6whrQ'
      }).addTo(mymap);
       let geocodeService = L.esri.Geocoding.geocodeService();
      
        geocodeService.reverse().latlng(option.center).run(function (error, result) {
          if (error) {
            return;
          }
      
          L.marker(result.latlng).addTo(mymap).bindPopup('<b>Universitas Nahdlatul Ulama Surabaya</b><br>'+result.address.Match_addr).openPopup();
        });
      
}

export { init, urlPage }