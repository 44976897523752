export default {
    notifMsg: (icon, message) => {
        swal({
            title: 'Pesan!',
            icon: icon,
            text: message
        })
    },
    setConfig: (method, cors = 'no-cors', headers = {}, body = {}) => {
        const config = {
            method: method,
            cors: cors,
            headers: headers,
            body: body
        }
        return config
    },
    reloadDT: (el) => {
        $(el).DataTable().ajax.reload()
    },
    removeDot: (number) => {
        return number.replace(/[ ,.]/g, '')
    },
    dateFormat: (dateParams) => {
        const date = new Date(dateParams)
        const monthFormat = (month) => {
            let resultMont = ''
            switch (month) {
                case 0:
                    resultMont = 'Januari'
                    break
                case 1:
                    resultMont = 'Februari'
                    break
                case 2:
                    resultMont = 'Maret'
                    break
                case 3:
                    resultMont = 'April'
                    break
                case 4:
                    resultMont = 'Mei'
                    break
                case 5:
                    resultMont = 'Juni'
                    break
                case 6:
                    resultMont = 'Juli'
                    break
                case 7:
                    resultMont = 'Agustus'
                    break
                case 8:
                    resultMont = 'September'
                    break
                case 9:
                    resultMont = 'Oktober'
                    break
                case 10:
                    resultMont = 'November'
                    break
                case 11:
                    resultMont = 'Desember'
                    break
                default:
                    resultMont = 'Bulan tidak ada'
                    break
            }
            return resultMont
        }
        const format =
            date.getDate() +
            ' ' +
            monthFormat(date.getMonth()) +
            ' ' +
            date.getFullYear()
        return format
    },
    clickEvent: (callback) => {
        if (Array.isArray(callback)) {
            for (let i of callback) {
                document.addEventListener('click', i)
            }
        }
        document.addEventListener('click', callback)
    },
    hapusValidasi: (key) => {
        let pesan = $('#' + key)
        let text = $('.' + key)
        text.removeClass('invalid-feedback')
        pesan.removeClass('is-invalid')
        text.text(null)
    },
    addValidation: (el, value) => {
        const pesan = document.getElementById(el)
        const text = document.querySelector(`.${el}`)
        pesan.classList.add('is-invalid')
        text.classList.add('invalid-feedback')
        text.textContent = value
    },
    filterToko: ({el = '#filter_toko', callback} = {}) => {
        const element = document.querySelector(el)
        if (element !== null) {
            $(el).on('select2:select', callback)
            // element.addEventListener('change', callback)
        }
    },
    formatRupiah: (money) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0
        }).format(money)
    },
    showLoading: () => {
        let i = 0
        const intervalTime = new Date().getTime()
        const elBodyLoader = document.querySelector('.body-loader')
        const elCounter = document.querySelector('.counter')
        elBodyLoader.style.display = ''
        elBodyLoader.classList.add('background-load')
        const counter = setInterval(function () {
            i++
            elCounter.textContent = i + '%'
            if (i === 100) {
                clearInterval(counter)
                document.querySelector('.counter').style.border = 'unset'
                elBodyLoader.style.display = 'none'
                elBodyLoader.classList.remove('.background-load')
            }
        }, intervalTime - new Date().getTime())
    }
}
