import Config from '../config/Config';
import Helper from '../config/Helper';
import {elid} from '../config/Element';
import axios from 'axios';

const urlParam = location.href.split('/');
const querySearch = Config.mode === 'dev' ? urlParam[6] : urlParam[5];
const urlPage =
    location.href === `${Config.url}/pasien/create-menu-pasien/${+querySearch}`
        ? `${Config.url}/pasien/create-menu-pasien/${+querySearch}`
        : `${Config.url}/pasien/edit-menu-pasien/${+querySearch}`;

const form = elid('form-menu-pasien');
let tempData = [];

function handleLoadTable() {
    $('#table').DataTable({
        responsive: true,
        lengtChange: true,
        autoWidth: false,
        data: tempData,
        columns: [
            {data: 'index', name: 'index'},
            {data: 'nama_bahan', name: 'nama_bahan'},
            {data: 'berat', name: 'berat'},
            {
                data: null,
                render: (data) =>
                    `<button class="btn btn-danger delete-data" type="button" data-index="${data.index}"><i class="fas fa-trash"></i></button>`,
            },
        ],
        columnDefs: [
            {
                targets: [0],
                visible: false,
                searchable: false,
            },
        ],
        destroy: true,
    });
}

function getTime(val) {
    switch (val) {
        case '1':
            elid('jam_makan').value = '07:00:00';
            break;
        case '2':
            elid('jam_makan').value = '10:00:00';
            break;
        case '3':
            elid('jam_makan').value = '12:00:00';
            break;
        case '4':
            elid('jam_makan').value = '15:00:00';
            break;
        case '5':
            elid('jam_makan').value = '17:00:00';
            break;
        case '6':
            elid('jam_makan').value = '20:00:00';
            break;
    }
}

let index = 0;
function addDataToTable() {
    tempData.push({
        index: index++,
        nama_bahan: elid('nama_bahan').value,
        berat: elid('berat').value,
    });
    handleLoadTable();
    console.log(tempData);
}

function deleteRow(e) {
    if (e.target.classList.contains('delete-data')) {
        const indexId = e.target.dataset.index;
        const findIndex = tempData.findIndex((i) => i.index === +indexId);
        tempData.splice(findIndex, 1);
        console.log(tempData);
        handleLoadTable();
    }
}

async function showDetailBahan() {
    try {
        const res = await axios(
            `${Config.url}/pasien/json-bahan-pasien/${+querySearch}`,
        );
        elid('id').value = res.data[0].id;
        elid('pasien_id').value = res.data[0].pasien_id;
        window.localStorage.setItem('pasien_id', res.data[0].pasien_id);
        elid('waktu_makan').value = res.data[0].jam_id;
        elid('jam_makan').value = res.data[0].jam_makan;
        elid('nama_menu').value = res.data[0].nama_menu;
        res.data[0].bahans.forEach((item) => {
            tempData.push({
                index: index++,
                nama_bahan: item.nama_bahan,
                berat: item.berat,
            });
        });
        handleLoadTable();
        console.log(res);
    } catch (err) {
        console.error(err);
    }
}

async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(form);
    formData.append('bahans', JSON.stringify(tempData));
    let icon = '';
    try {
        const store = await axios.post(
            `${Config.url}/pasien/menu/simpan`,
            formData,
        );
        if (store.data.errors === undefined) {
            if (
                urlPage !==
                `${Config.url}/pasien/edit-menu-pasien/${+querySearch}`
            ) {
                tempData = [];
            }
            elid('berat').value = 0;
            elid('nama_bahan').value = '';
            icon = 'success';
            console.log(store.data)
            handleLoadTable();
        } else {
            console.log(store.data.errors);
            const errors = Object.entries(store.data.errors);
            errors.map(([key, value]) => Helper.addValidation(key, value));
            icon = 'error';
        }
        Helper.notifMsg(icon, store.data.message);
    } catch (err) {
        console.error(err);
    } finally {
        Helper.showLoading();
    }
}

let reloadPage = '';
function init() {
    if (urlPage === `${Config.url}/pasien/edit-menu-pasien/${+querySearch}`) {
        showDetailBahan();
    }
    const pasienId =
        window.localStorage.getItem('pasien_id') ?? elid('pasien_id').value;
    reloadPage = `${Config.url}/pasien/show-menu-pasien/${pasienId}`;

    // console.log(reloadPage)
    // return
    handleLoadTable();
    $('.select2').select2({
        theme: 'bootstrap4',
    });
    form.addEventListener('submit', handleSubmit);
    elid('btn-add-menu').onclick = addDataToTable;
    elid('waktu_makan').onchange = (e) => getTime(e.target.value);
    elid('btn-back').onclick = () => location.assign(reloadPage);
    elid('look-detail').onclick = () =>
        location.assign(`${Config.url}/pasien/detail-pasien/${+querySearch}`);
    elid('btn-back-pasien').onclick = () =>
        location.assign(`${Config.url}/pasien`);
    Helper.clickEvent(deleteRow);
}

export {init, urlPage};
