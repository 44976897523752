import Config from '../config/Config'
import Helper from '../config/Helper'
import { elid } from '../config/Element'
import axios from 'axios'

const urlPage = `${Config.url}/manajemen-bahan/bahan-tkpi`
const form = elid('form-bahan-tkpi')
function handleLoadTable() {
    $('#table').DataTable({
        processing: true,
        serverSide: true,
        responsive: true,
        lengtChange: true,
        autoWidth: false,
        ajax: {
            url: `${urlPage}/json-dt`
        },
        columns: [
            { data: 'DT_RowIndex', name: 'id' },
            { data: 'kode', name: 'kode' },
            { data: 'nama_bahan', name: 'nama_bahan' },
            { data: 'sumber_bahan', name: 'sumber_bahan' },
            { data: 'action', name: 'action' }
        ],
        order: [[0, 'desc']],
        destroy: true
    })
}

function getDetailData(id) {
    return new Promise((resolve, reject) => {
        try {
            resolve(axios(`${urlPage}/show/${id}`))
        } catch (err) {
            reject(err)
        }
    })
}

function handleShowModal(e) {
    if (e.target.id === 'modalTambah') {
        const id = elid('id')
        if (id.value !== '') {
            id.value = ''
        }
        form.reset()
        $('#modal').modal({
            show: true,
            backdrop: 'static'
        })
    }
}

async function handleDetail(e) {
    if (e.target.classList.contains('detail')) {
        $('#modal-detail').modal({
            show: true,
            backdrop: 'static'
        })
        try {
            const id = e.target.dataset.id || e.target.parentNode.dataset.id
            const data = await getDetailData(id)
            renderDetailData(data.data)
        } catch (err) {
            console.error(err)
        }
    }
}

function renderDetailData(data) {
    const html = `
         <div class="input-group m-auto pb-4">
            <input type="hidden" name="id_bahan" id="id_bahan" value="${data.id}"/>
            <input type="numeric" name="bagi" id="bagi" class="form-control" placeholder="Masukkan angka">
            <span class="input-group-btn">
                <div class="btn-group">
                    <button class="btn btn-success refresh">Refresh</button>
                    <button type="button" id="submit" class="btn btn-primary calc">Hitung</button>
                </div>
            </span>
        </div>
        <table class="table" id="mytable" style="width: 100%;">
          <tr>
            <td>air <small>(gr)</small></td>
            <td>:</td>
            <td>${data.air ?? 0}</td>
          </tr>
          <tr>
            <td>energi <small>(kal)</small></td>
            <td>:</td>
            <td>${data.energi ?? 0}</td>
          </tr>
          <tr>
            <td>protein <small>(gr)</small></td>
            <td>:</td>
            <td>${data.protein ?? 0}</td>
          </tr>
          <tr>
            <td>lemak <small>(gr)</small></td>
            <td>:</td>
            <td>${data.lemak ?? 0}</td>
          </tr>
          <tr>
            <td>karbohidrat <small>(gr)</small></td>
            <td>:</td>
            <td>${data.kh ?? 0}</td>
          </tr>
          <tr>
            <td>abu <small>(gr)</small></td>
            <td>:</td>
            <td>${data.abu ?? 0}</td>
          </tr>
          <tr>
            <td>kalsium <small>(mg)</small></td>
            <td>:</td>
            <td>${data.kalsium ?? 0}</td>
          </tr>
          <tr>
            <td>fosfor <small>(mg)</small></td>
            <td>:</td>
            <td>${data.fosfor ?? 0}</td>
          </tr>
          <tr>
            <td>besi <small>(mg)</small></td>
            <td>:</td>
            <td>${data.besi ?? 0}</td>
          </tr>
          <tr>
            <td>natrium <small>(mg)</small></td>
            <td>:</td>
            <td>${data.natrium ?? 0}</td>
          </tr>
          <tr>
            <td>kalium <small>(mg)</small></td>
            <td>:</td>
            <td>${data.kalium ?? 0}</td>
          </tr>
          <tr>
            <td>tembaga <small>(mg)</small></td>
            <td>:</td>
            <td>${data.tembaga ?? 0}</td>
          </tr>
          <tr>
            <td>seng <small>(mg)</small></td>
            <td>:</td>
            <td>${data.seng ?? 0}</td>
          </tr>
        </table>
    `
    elid('content-modal').innerHTML = html
}

async function calculateDetailBahan(e) {
    if (e.target.classList.contains('calc')) {
        try {
            const id = elid('id_bahan').value
            const data = await getDetailData(id)
            const mapping = Object.entries(data.data)
            let temp = {}
            const notAllowed = ['created_at', 'updated_at', 'id', 'kode', 'nama_bahan', 'sumber_bahan']
            mapping.map(([key, value]) => {
                if (!notAllowed.includes(key)) {
                    temp[key] = value * elid('bagi').value / 100
                }
                temp['id'] = data.data.id
            })
            renderDetailData(temp)
        } catch (err) {
            console.error(err)
        }
    }
}

async function refreshCalculateDetailBahan(e) {
    if (e.target.classList.contains('refresh')) {
        try {
            const id = elid('id_bahan').value
            const data = await getDetailData(id)
            renderDetailData(data.data)
        } catch (err) {
            console.error(err)
        }
    }
}

function handleDelete(e) {
    if (e.target.classList.contains('delete')) {
        const id = e.target.dataset.id || e.target.parentNode.dataset.id
        const urlDelete = `${urlPage}/hapus/${+id}`
        swal({
            title: 'Apakah Kamu Yakin ?',
            text: 'Setelah dihapus anda tidak dapat memulihkan data ini!',
            icon: 'warning',
            buttons: ['No, cancel!', 'Yes, delete it!'],
        }).then((result) => {
            if (result) {
                axios
                    .delete(urlDelete)
                    .then((res) => {
                        handleLoadTable()
                        swal({
                            title: 'Pesan!',
                            text: res.data.message,
                            icon: 'success'
                        })
                    })
                    .catch((err) => {
                        console.error(err)
                        Helper.notifMsg(
                            'warning',
                            'Data ini tidak boleh dihapus'
                        )
                    }).finally(() => Helper.showLoading())
            }
        })
    }
}

async function handleEdit(e) {
    if (e.target.classList.contains('update')) {
        elid('modalTitle').innerText = 'Ubah Data Bahan TKPI'
        $('#modal').modal({
            show: true,
            backdrop: 'static'
        })
        const id = e.target.dataset.id || e.target.parentNode.dataset.id
        try {
            const getData = await axios(`${urlPage}/show/${+id}`)
            const notAllowed = ['created_at', 'updated_at']
            for (const key in getData.data) {
                if (!notAllowed.includes(key)) {
                    document.getElementById(key).value = getData.data[key]
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
}

async function handleSubmit(e) {
    e.preventDefault()
    const formData = new FormData(form)
    let icon = ''
    try {
        const postData = await axios({
            method: 'POST',
            url: `${urlPage}/simpan`,
            data: formData
        })
        if (postData.data.errors === undefined) {
            for (const key in postData.data.success) {
                Helper.hapusValidasi(key)
            }
            handleLoadTable()
            $('#modal').modal('hide')
            form.reset()
            icon = 'success'
        } else {
            const errors = Object.entries(postData.data.errors)
            errors.map(([key, value]) => Helper.addValidation(key, value))
            icon = 'error'
        }
        Helper.notifMsg(icon, postData.data.message)
    } catch (error) {
        console.error(error)
    } finally {
        Helper.showLoading()
    }
}

function init() {
    handleLoadTable()
    form.addEventListener('submit', handleSubmit)
    Helper.clickEvent([handleShowModal, handleDelete, handleEdit, handleDetail, calculateDetailBahan, refreshCalculateDetailBahan])
}

export { init, urlPage }
