const config = {
    mode: process.env.NODE_ENV,
    csrf: { "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content") },
};
config.url =
    config.mode === "development"
        ? "http://lara-menu-harianku.test"
        : "https://menu-harianku.yukbelajar.education";
console.log(config)
export default config;
