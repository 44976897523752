import * as ListPasien from '../pages/pasien/ListPasien';
import * as CreatePasien from '../pages/pasien/CreatePasien';
import * as BahanTkpi from '../pages/BahanTkpi';
import * as BahanPribadi from '../pages/BahanPribadi';
import * as Menu from '../pages/Menu';
import * as User from '../pages/User';
import * as PMenu from '../pages/public/PublicMenu';
import * as PKontak from '../pages/public/ContactUs';
import * as Register from '../pages/public/Register';
import * as Profile from '../pages/Profile';
import * as ChangePassword from '../pages/ChangePassword';
import * as DetailPasien from '../pages/pasien/DetailPasien';
const pages = [
    ListPasien,
    BahanTkpi,
    BahanPribadi,
    CreatePasien,
    Menu,
    User,
    PMenu,
    PKontak,
    Register,
    Profile,
    ChangePassword,
    DetailPasien,
];
export default pages;
