import axios from 'axios';
import Config from '../config/Config';
import Helper from '../config/Helper';

const urlPage = `${Config.url}/pengaturan/change-password`;
const form = document.getElementById('form-change-password');
async function handleSubmit(e) {
    e.preventDefault();
    let icon = '';
    const formData = new FormData(form);
    try {
        const store = await axios({
            method: 'POST',
            url: `${urlPage}/simpan`,
            data: formData,
        });
        if (store.data.error === undefined) {
            for (const i in store.data.success) {
                if (store.data.success[i] !== 'avatar') {
                    Helper.hapusValidasi(i);
                }
            }
            icon = 'success';
            form.reset();
        } else {
            const error = Object.entries(store.data.error);
            error.map(([key, value]) => Helper.addValidation(key, value));
            icon = 'error';
        }
        Helper.notifMsg(icon, store.data.message);
    } catch (e) {
        Helper.notifMsg('error', 'Something Wrong');
        console.error(err);
    }
}
const init = () => {
    form.addEventListener('submit', handleSubmit);
};

export { init, urlPage };
