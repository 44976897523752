import Config from '../../config/Config';
import Helper from '../../config/Helper';
import { elid } from '../../config/Element';
import axios from 'axios';

const urlParam = location.href.split('/');
const querySearch = Config.mode === 'dev' ? urlParam[6] : urlParam[5];
// const urlPage =
//     location.href === `${Config.url}/pasien/create`
//         ? `${Config.url}/pasien/create`
//         : `${Config.url}/pasien/edit/${urlParam[5]}`;
const urlPage =
    location.href === `${Config.url}/pasien/create`
        ? `${Config.url}/pasien/create`
        : `${Config.url}/pasien/edit/${querySearch}`;

const form = elid('form-pasien');

async function handleSubmit(e) {
    e.preventDefault();
    let icon = '';
    const formData = new FormData(form);
    try {
        const store = await axios.post(
            `${Config.url}/pasien/create/simpan`,
            formData,
        );
        if (store.data.errors === undefined) {
            for (const key in store.data.success) {
                Helper.hapusValidasi(key);
            }
            swal({
                title: 'Pesan!',
                text: store.data.message,
                icon: 'success',
                buttons: ['Cancel', 'Susun Menu Pasien'],
            }).then((result) => {
                if (result) {
                    location.assign(
                        `${Config.url}/pasien/create-menu-pasien/${store.data.id}`,
                    );
                }
            });
        } else {
            const errors = Object.entries(store.data.errors);
            errors.map(([key, value]) => Helper.addValidation(key, value));
            icon = 'error';
            Helper.notifMsg(icon, store.data.message);
        }
    } catch (err) {
        Helper.notifMsg('error', 'Mohon Maaf Ada Kesalahan Server');
        console.error(err);
    } finally {
        Helper.showLoading();
    }
}

async function handleShowDataPasien() {
    try {
        const response = await axios(
            `${Config.url}/pasien/show-pasien/${querySearch}`,
        );
        const data = response.data;
        const notAllowed = [];
        if (data.user_id !== null) {
            notAllowed.push('created_at', 'updated_at');
        } else {
            notAllowed.push('created_at', 'updated_at', 'user_id');
        }
        for (const element in data) {
            if (!notAllowed.includes(element)) {
                elid(element).value = data[element];
            }
        }
    } catch (err) {
        console.error(err);
        Helper.notifMsg('error', 'Mohon Maaf Ada Kendala Server');
    }
}

function init() {
    if (urlPage === `${Config.url}/pasien/edit/${querySearch}`) {
        handleShowDataPasien();
    }
    form.addEventListener('submit', handleSubmit);
}

export { init, urlPage };
